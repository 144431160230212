import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { differenceInHours } from 'date-fns/differenceInHours';
import { roundToNearestHours } from 'date-fns/roundToNearestHours';
import { subHours } from 'date-fns/subHours';
import Konva from 'konva';
import { Shape } from 'konva/lib/Shape';
import { Vector2d } from 'konva/lib/types';
import { useEffect, useState, DragEvent as ReactDragEvent, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import 'konva/lib/shapes/Text';
import 'konva/lib/shapes/Label';
import 'konva/lib/shapes/Image';

import {
  BlueprintCanvasContext,
  BlueprintMoisturePlot,
} from 'components/BlueprintCanvas/components/';
import CanvasStage, { ImageSize, LabelProps } from 'components/CanvasStage';
import LoadingCard from 'components/cards/LoadingCard';
import BlueprintSensorModal from 'components/modals/BlueprintSensorModal';
import SensorSelectModal from 'components/modals/SensorSelectModal';
import SensorIcon from 'images/tector-icon-black.png';
import { brandBlue, getSensorStateColor } from 'utils/colors';
import { BlueprintViewStateContext } from 'utils/contexts';
import { sensorId2NameFunc } from 'utils/formatting';
import { useImage, useTimePeriod, useWindowSize } from 'utils/hooks';
import {
  useBlueprint,
  useBlueprintPositions,
  useBlueprintSensorRiskScoreValues,
  useBlueprintSensors,
  useBlueprintSensorValues,
} from 'utils/hooks/data';
import { truncateNumber } from 'utils/numbers';
import { convertRiskScoreToRiskIndex } from 'utils/risk-index';
import { signalStrengthToState } from 'utils/sensor/state';
import { getSignalStrengthStateText } from 'utils/sensor/texts';
import { getSensorTransmission } from 'utils/sensor/transmissions';
import { BlueprintPosition, BlueprintViewStateType } from 'utils/types';

export const Canvas: React.FC<{
  blueprintId: string;
  stageHeight?: number;
  onlyEnableSensorIds?: string[];
  onlyShowSensorIds?: string[];
  onlyShowTooltipforSensorIds?: string[];
  enableToolbox?: boolean;
  enableWheelScrolling?: boolean;
  enableDragging?: boolean;
  enableClicking?: boolean;
}> = ({
  blueprintId,
  onlyEnableSensorIds,
  onlyShowTooltipforSensorIds,
  onlyShowSensorIds,
  stageHeight = 500,
  enableToolbox = false,
  enableWheelScrolling = false,
  enableDragging = false,
  enableClicking = false,
}) => {
  const { t } = useTranslation('components');

  // States
  const [blueprintImageSize, setBlueprintImageSize] = useState<ImageSize>({ width: 0, height: 0 });
  const [sensorSelectModalState, setSensorSelectModalState] = useState<{
    show: boolean;
    x?: number;
    y?: number;
  }>({ show: false });
  const [modalBlueprintPositionState, setModalBlueprintPositionState] = useState<{
    blueprintPosition?: BlueprintPosition;
    enabled?: boolean;
    show: boolean;
  }>({ show: false });

  // Canvas size
  const [windowWidth] = useWindowSize();
  const canvasDifRef = useRef<HTMLDivElement>(null);
  const [canvasSize, setCanvasSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  // Pop-up moisture plot
  const [showMoisturePlot, setShowMoisturePlot] = useState(false);
  const [moisturePlotPosition, setMoistureplotPosition] = useState<Vector2d>({ x: 0, y: 0 });
  const [moisturePlotActiveKonvaElement, setMoisturePlotActiveKonvaElement] = useState<
    Shape | undefined
  >(undefined); // This is used to propagate the onclick event from the moisture plot to the sensor icon
  const onMoisturePlotHide = () => {
    setShowMoisturePlot(false);
    setMoistureplotPosition({ x: -1000, y: -1000 });
  };

  // Contexts
  const {
    hours,
    setHours,
    lockSensors,
    setLockSensors,
    activeBlueprintPosition,
    setActiveBlueprintPosition,
  } = useContext(BlueprintCanvasContext);
  const { blueprintViewState } = useContext(BlueprintViewStateContext);

  // Tooltips are enabled if any of the three options are enabled
  const highlightCertainSensors = !!onlyEnableSensorIds && onlyEnableSensorIds.length > 0;

  // Refs to store references to the Label and Image components for highlighted sensors
  const highlightedLabelsRefs = useRef<(Konva.Label | null)[]>([]);
  const highlightedImagesRefs = useRef<(Konva.Image | null)[]>([]);

  // Hooks
  const { blueprint, isPending: isPendingBlueprint } = useBlueprint(blueprintId);
  const {
    sensors,
    removeSensorFromBlueprintById,
    isPending: isPendingSensors,
  } = useBlueprintSensors(blueprintId);
  const {
    blueprintPositions,
    updateSensorPositionById,
    isPending: isPendingPositions,
  } = useBlueprintPositions(blueprintId);

  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();
  const max = differenceInHours(timeTo, timeFrom);

  const { sensorId2Transmission: sensorId2TransmissionWithoutRiskScore } = useBlueprintSensorValues(
    blueprintId,
    {
      timeFrom: subHours(timeFrom, 12), // ensuring there is transmisisons from the timelines beginning
      timeTo,
    },
  );
  const { sensorId2Transmission: sensorId2TransmissionWithRiskScore } =
    useBlueprintSensorRiskScoreValues(
      blueprintId,
      {
        timeFrom: subHours(timeFrom, 12), // ensuring there is transmisisons from the timelines beginning
        timeTo,
      },
      { enableGet: blueprintViewState === BlueprintViewStateType.RiskScore },
    );

  const sensorId2Transmission =
    blueprintViewState === BlueprintViewStateType.RiskScore
      ? sensorId2TransmissionWithRiskScore
      : sensorId2TransmissionWithoutRiskScore;

  // Images
  const [blueprintImage] = useImage(blueprint?.storage_url);
  const [sensorImage] = useImage(SensorIcon);

  // Mappings
  const sensorId2Name = sensorId2NameFunc(sensors);
  const positionIdToPosition = Object.fromEntries(
    (blueprintPositions || []).map(position => [position.id, position]),
  );
  const isSensorIdEnabled = Array.isArray(onlyEnableSensorIds)
    ? Object.fromEntries(
        (sensors || []).map(sensor => [sensor.id, onlyEnableSensorIds.includes(sensor.id)]),
      )
    : Object.fromEntries((sensors || []).map(sensor => [sensor.id, true]));

  // Handle sensor dropped on div
  const onDrop = async (
    {
      xFraction,
      yFraction,
    }: {
      xFraction: number;
      yFraction: number;
    },
    e: ReactDragEvent<HTMLDivElement>,
  ) => {
    const blueprintSensorId = e.dataTransfer!.getData('blueprint-sensor-id');

    if (blueprintSensorId) {
      await updateSensorPositionById({
        sensorId: blueprintSensorId,
        position_x: xFraction,
        position_y: yFraction,
      });
    }
  };

  const handleOnBlueprintClick = ({ evt, target }: Konva.KonvaEventObject<MouseEvent>) => {
    evt.preventDefault();
    if (!enableClicking) return;

    const stage = target.getStage();
    if (!stage) return;

    const scale = stage?.scaleX();
    const pointerPosition = stage.getPointerPosition()!;

    setSensorSelectModalState({
      show: true,
      x: pointerPosition.x / scale - stage.x() / scale,
      y: pointerPosition.y / scale - stage.y() / scale,
    });
  };

  const tooltipText = (sensorId?: string) => {
    if (!sensorId2Transmission || !sensorId) return;

    const transmission = getSensorTransmission(timeTo, max, hours, sensorId2Transmission, sensorId);
    const name = sensorId2Name[sensorId];

    const tooltipLines: string[] = [];
    tooltipLines.push(`${name}`);
    const { moisture, spreading_factor, risk_score } = transmission;
    if (blueprintViewState === BlueprintViewStateType.Moisture) {
      tooltipLines.push(`WMC: ${moisture ? `${moisture.toFixed(1)}%` : 'n/a'}`);
    } else if (blueprintViewState === BlueprintViewStateType.SignalStrength) {
      tooltipLines.push(
        `${spreading_factor ? `${getSignalStrengthStateText(signalStrengthToState(transmission.spreading_factor))}` : 'n/a'}`,
      );
    } else if (blueprintViewState === BlueprintViewStateType.RiskScore) {
      const riskIndex = convertRiskScoreToRiskIndex(risk_score);
      tooltipLines.push(
        `${typeof riskIndex === 'number' ? `Risk: ${riskIndex.toFixed(1)}` : 'n/a'}`,
      );
    }

    return tooltipLines.join('\n');
  };

  // Determine what images to show
  const blueprintPositionsToShow = onlyShowSensorIds
    ? (blueprintPositions || []).filter(blueprintPosition =>
        onlyShowSensorIds.includes(blueprintPosition.sensor_id),
      )
    : blueprintPositions || [];

  // Determine what tooltips to show
  const blueprintPositionsToShowTooltip = onlyShowTooltipforSensorIds
    ? blueprintPositionsToShow.filter(blueprintPosition =>
        onlyShowTooltipforSensorIds.includes(blueprintPosition.sensor_id),
      )
    : blueprintPositionsToShow;

  // Get the canvas width and height to position the moisture plot
  useEffect(() => {
    const div = canvasDifRef?.current;
    if (!div) return;
    const rect = div.getBoundingClientRect();
    setCanvasSize({ width: rect.width, height: rect.height });
  }, [canvasDifRef, windowWidth]);

  // Callback on mouse enter
  const handleOnMouseEnterImageAndLabel = ({ target }: Konva.KonvaEventObject<MouseEvent>) => {
    if (!enableClicking) return;

    const isImage = target instanceof Konva.Image;

    const position = positionIdToPosition[target.attrs.blueprintPositionId];
    const enabled = target.attrs.enabled;

    const container = target!.getStage()!.container();
    container.style.cursor = !lockSensors && enabled && isImage ? 'grab' : 'pointer';

    if (setActiveBlueprintPosition && position) setActiveBlueprintPosition(position);

    // Update moisture pop-up plot
    if (position) {
      const xPos = target!.getStage()!.getPointerPosition()!.x;
      const yPos = target!.getStage()!.getPointerPosition()!.y;
      setMoistureplotPosition({ x: xPos, y: yPos });
      if (isImage) {
        setMoisturePlotActiveKonvaElement(target);
      }
    }
  };

  // Callback on mouse leave
  const handleOnMouseLeaveImageAndLabel = ({ target }: Konva.KonvaEventObject<MouseEvent>) => {
    if (!enableClicking) return;

    const container = target!.getStage()!.container();
    container.style.cursor = 'default';
  };

  // Callback on on drag end
  const handleOnDragEndImage = async ({ target }: Konva.KonvaEventObject<DragEvent>) => {
    // Do not allow position outside boundary of image
    const x = truncateNumber(target.x(), 0, blueprintImageSize.width);
    const y = truncateNumber(target.y(), 0, blueprintImageSize.height);

    // Compute fractions for backend
    const xFraction = x / blueprintImageSize.width;
    const yFraction = y / blueprintImageSize.height;

    const position = positionIdToPosition[target.attrs.blueprintPositionId];

    await updateSensorPositionById({
      sensorId: position.sensor_id,
      position_x: xFraction,
      position_y: yFraction,
    });
  };

  // Callback on click
  const handleOnClickImageAndLabel = ({ currentTarget }: Konva.KonvaEventObject<DragEvent>) => {
    if (!enableClicking) return;

    const position = positionIdToPosition[currentTarget.attrs.blueprintPositionId];
    const enabled = currentTarget.attrs.enabled;

    setModalBlueprintPositionState({
      blueprintPosition: position,
      show: true,
      enabled,
    });
  };

  // Create images
  const images = blueprintPositionsToShow
    .filter(blueprintPosition => blueprintPosition.isPositionDefined)
    .map((blueprintPosition, index) => {
      const active = activeBlueprintPosition?.id === blueprintPosition.id;
      const enabled = isSensorIdEnabled[blueprintPosition.sensor_id];
      const highlighted = highlightCertainSensors && enabled;
      const scale = sensorImage ? sensorImage.height / sensorImage.width : 1.0;
      const width = active ? 40 : 30;
      const height = sensorImage ? width * scale : 0;

      return {
        ref: (el: any) => {
          if (el) {
            highlightedImagesRefs.current[index] = highlighted ? el : null;
          }
        },
        image: sensorImage,
        width,
        height,
        x: blueprintPosition.position_x,
        y: blueprintPosition.position_y,
        opacity: 1.0,
        draggable: enabled && !lockSensors,
        onDragEnd: handleOnDragEndImage,
        onMouseEnter: handleOnMouseEnterImageAndLabel,
        onMouseLeave: handleOnMouseLeaveImageAndLabel,
        onClick: handleOnClickImageAndLabel,
        blueprintPositionId: blueprintPosition.id,
        enabled,
      } as Konva.ImageConfig;
    });

  // Create labels
  const labels = blueprintPositionsToShowTooltip
    ?.filter(position => position.isPositionDefined)
    ?.map((position, i) => {
      const stateColor = getSensorStateColor(
        timeTo,
        max,
        hours,
        sensorId2Transmission,
        position.sensor_id,
        blueprintViewState,
      );

      const enabled = isSensorIdEnabled[position.sensor_id];
      const highlighted = highlightCertainSensors && enabled;
      const strokeEnabled = highlighted ? true : false;

      return {
        ref: (el: any) => {
          if (el) {
            highlightedLabelsRefs.current[i] = highlighted ? el : null;
          }
        },
        visible: true,
        x: position.position_x!,
        y: position.position_y!,
        opacity: 1.0,
        onMouseEnter: handleOnMouseEnterImageAndLabel,
        onMouseLeave: handleOnMouseLeaveImageAndLabel,
        onClick: handleOnClickImageAndLabel,
        blueprintPositionId: position.id,
        enabled,
        tag: {
          fill: stateColor,
          pointerDirection: 'down',
          pointerWidth: 10,
          pointerHeight: 10,
          lineJoin: 'round',
          cornerRadius: 200,
          strokeEnabled,
          stroke: brandBlue,
          strokeWidth: 4,
        },
        text: {
          text: `${tooltipText(position.sensor_id)}`,
          fontFamily: 'Montserrat',
          fontSize: 12,
          padding: 12,
          fill: '#ffffff',
        },
      } as LabelProps;
    });

  useEffect(() => {
    setHours(differenceInHours(roundToNearestHours(timeTo), roundToNearestHours(timeFrom)));
  }, [timeFrom, timeTo, setHours]);

  // Move highlighted sensors on top
  if (highlightCertainSensors) {
    highlightedImagesRefs.current.forEach(ref => ref?.moveToTop());
    highlightedImagesRefs.current.forEach(ref => ref?.moveToTop());
  }

  // Define available sensors
  const nonAttachedBlueprintIds = blueprintPositions
    ?.filter(x => !x.isPositionDefined)
    .map(x => x.sensor_id);
  const availableSensors = (sensors || [])
    .filter(sensor => nonAttachedBlueprintIds?.includes(sensor.id))
    .filter(sensor => isSensorIdEnabled[sensor.id]);

  const isPending = isPendingBlueprint || isPendingSensors || isPendingPositions;

  if (isPending) return <LoadingCard count={4} />;

  const onPanCanvas = ({ evt, target }: Konva.KonvaEventObject<DragEvent>) => {
    // There are some cases where the user is able to pan when the moisture pop up plot is active,
    // this handles it by keeping the x and y pos up to date as we're panning
    if (!enableClicking || !showMoisturePlot) return;
    const xPos = target!.getStage()!.getPointerPosition()!.x;
    const yPos = target!.getStage()!.getPointerPosition()!.y;
    setMoistureplotPosition({ x: xPos, y: yPos });
  };

  return (
    <div className="relative" ref={canvasDifRef}>
      <CanvasStage
        stageHeight={stageHeight}
        imageSize={blueprintImageSize}
        setImageSize={setBlueprintImageSize}
        backgroundImage={blueprintImage}
        imageConfigs={images}
        labelConfigs={labels}
        onClick={handleOnBlueprintClick}
        onDrop={onDrop}
        enableWheelScrolling={enableWheelScrolling}
        enableDragging={enableDragging}
        enableToolbox={enableToolbox}
        topToolboxButtons={
          <>
            {/* Lock canvas button */}
            <FontAwesomeIcon
              className={classNames(
                'cursor-pointer text-brand-gray rounded-md p-3 lg:w-5 lg:h-5 outline-none',
                {
                  'bg-green-400': lockSensors,
                  'bg-red-400': !lockSensors,
                },
              )}
              onClick={() => setLockSensors && setLockSensors(val => !val)}
              icon={lockSensors ? faLock : faUnlock}
              data-tooltip-content={
                lockSensors
                  ? t('blueprints.BlueprintCanvas.unlock')
                  : t('blueprints.BlueprintCanvas.lock')
              }
              data-tooltip-id="route-tooltip"
            />
          </>
        }
        onDragEnd={onPanCanvas}
        onDragStart={() => {
          console.log('onDragStart');
        }}
      />
      {/* Plots */}
      <BlueprintMoisturePlot
        blueprintId={blueprintId}
        show={showMoisturePlot}
        selectedSensorId={activeBlueprintPosition?.sensor_id}
        selectedSensorName={
          activeBlueprintPosition?.sensor_id
            ? sensorId2Name[activeBlueprintPosition.sensor_id]
            : undefined
        }
        position={moisturePlotPosition}
        timeFrom={timeFrom}
        timeTo={timeTo}
        canvasSize={canvasSize}
        onHide={onMoisturePlotHide}
        konvaElement={moisturePlotActiveKonvaElement}
        onClearBluePrintPosition={() => setActiveBlueprintPosition(undefined)}
      />
      {/* Modals */}
      <BlueprintSensorModal
        blueprintPosition={modalBlueprintPositionState.blueprintPosition}
        show={modalBlueprintPositionState.show}
        showActions={modalBlueprintPositionState.enabled}
        setShow={show => {
          if (!show) {
            setModalBlueprintPositionState({ show: false });
          }
        }}
        onDetach={async blueprintPosition => {
          await removeSensorFromBlueprintById(blueprintPosition.sensor_id);
          setModalBlueprintPositionState({ show: false });
        }}
        onResetPosition={async blueprintPosition => {
          await updateSensorPositionById({
            sensorId: blueprintPosition.sensor_id,
            position_x: null,
            position_y: null,
          });
          setModalBlueprintPositionState({ show: false });
        }}
      />
      <SensorSelectModal
        show={sensorSelectModalState.show}
        setShow={show =>
          setSensorSelectModalState({
            ...sensorSelectModalState,
            show,
          })
        }
        onSelect={async sensor => {
          if (sensor && sensorSelectModalState.x && sensorSelectModalState.y) {
            const xFraction = sensorSelectModalState.x / blueprintImageSize.width;
            const yFraction = sensorSelectModalState.y / blueprintImageSize.height;

            await updateSensorPositionById({
              sensorId: sensor.id,
              position_x: xFraction,
              position_y: yFraction,
            });

            setSensorSelectModalState({
              show: false,
            });
          }
        }}
        sensors={availableSensors}
        infoText={t('blueprints.BlueprintCanvas.SensorSelectModal.infoText')}
      />
    </div>
  );
};
