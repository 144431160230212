import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from 'utils/api';
import { floorToLatest5Minutes } from 'utils/date';
import { useSensor } from 'utils/hooks/data/sensors/useSensor';
import { parseTransmissionsMixed } from 'utils/sensor/transmissions';
import HookOptions from 'utils/types/HookOptions';
import SensorStats, { SensorsStats } from 'utils/types/SensorStats';
import Transmission, { parseTransmission } from 'utils/types/Transmission';
import TransmissionAnomaly, { parseTransmissionAnomaly } from 'utils/types/TransmissionAnomaly';

type ReturnProps = {
  transmissions: Transmission[];
  stats?: SensorsStats;
  emc?: number;
  emcValues?: number[];
  transmissionAnomalies?: TransmissionAnomaly[];
  referenceValues?: number[];
};

export const useSensorTransmissions = (
  sensorId: string,
  {
    fromTimestamp,
    toTimestamp,
    limit,
    includeMetaColumns = false,
  }: {
    fromTimestamp: Date;
    toTimestamp: Date;
    limit?: number;
    includeMetaColumns?: boolean;
  },
  { enableGet = true }: HookOptions = {},
) => {
  fromTimestamp = floorToLatest5Minutes(fromTimestamp);
  toTimestamp = floorToLatest5Minutes(toTimestamp);

  const queryKey = [
    'sensorTransmissions',
    sensorId,
    fromTimestamp,
    toTimestamp,
    limit,
    includeMetaColumns,
  ];

  const queryClient = useQueryClient();

  // Sensor Transmissions - Get
  const { data, isPending, isLoading, isFetching } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/sensors/${sensorId}/transmissions_with_stats`, {
        params: {
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
          limit,
          include_meta_columns: includeMetaColumns,
        },
      }),
    select: ({
      transmissions,
      stats,
      emc,
      weighted_emc,
      reference_values,
      transmission_anomalies,
    }) =>
      ({
        transmissions: transmissions.map(parseTransmission),
        stats,
        emc,
        emcValues: weighted_emc,
        transmissionAnomalies: transmission_anomalies?.map(parseTransmissionAnomaly),
        referenceValues: reference_values,
      }) as ReturnProps,
    enabled: !!sensorId && enableGet,
  });
  const transmissionsRaw = data?.transmissions;
  const transmissionAnomaliesRaw = data?.transmissionAnomalies;
  const statsRaw = data?.stats;
  const emc = data?.emcValues;
  const referenceValues = data?.referenceValues;

  // Get sensor
  const { sensor } = useSensor(sensorId);

  // Parse transmissions
  const transmissions = useMemo(
    () =>
      parseTransmissionsMixed({
        transmissions: transmissionsRaw,
        sensors: sensor ? [sensor] : [],
        anomalies: transmissionAnomaliesRaw,
      }),
    [transmissionsRaw, transmissionAnomaliesRaw, sensor],
  );

  // Parse stats
  const stats = useMemo(() => {
    if (!statsRaw || !sensor) return undefined;
    const sensorStats = statsRaw[sensor.hardware_id as keyof SensorStats];
    if (!sensorStats) return undefined;
    sensorStats.sensor = sensor;
    return sensorStats;
  }, [statsRaw, sensor]);

  // Update anomalies in period
  const { mutateAsync: updateAnomaliesInPeriod } = useMutation({
    mutationKey: queryKey,
    mutationFn: async ({
      fromTimestamp,
      toTimestamp,
      ...values
    }: Pick<TransmissionAnomaly, 'type' | 'description' | 'validation'> & {
      fromTimestamp: Date;
      toTimestamp: Date;
    }) =>
      api.patch(`/sensors/${sensorId}/anomalies`, {
        from_timestamp: fromTimestamp,
        to_timestamp: toTimestamp,
        ...values,
      }),
    onSuccess: async res => {
      queryClient.invalidateQueries({
        queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ['adminInsightsCustomers'],
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey: ['adminInsightsTransmissions'],
        exact: false,
      });
    },
  });

  return {
    transmissions,
    updateAnomaliesInPeriod,
    stats,
    isPending: isPending || isLoading || isFetching,
    emc,
    referenceValues,
  };
};
