import { customAlphabet } from 'nanoid';
import { SyntheticEvent, useMemo, useState } from 'react';
import { RawNodeDatum } from 'react-d3-tree';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { GroupHierarchyNodeCardBody } from 'components/GroupHierarchy/components/GroupHierarchyNode/components/GroupHierarchyNodeCard/components/GroupHierarchyNodeCardBody';
import GroupType from 'utils/enums/GroupType';
import { getIllustrations, getFallbackIllustration } from 'utils/groups/illustrations';
import { useCurrentUser, useSelectedCustomer } from 'utils/hooks';
import {
  useSensorGroup,
  useSensorGroupFile,
  useSensorGroupGateways,
  useSensorGroupParentGroups,
  useSensorGroupSensors,
  useSensorGroupSubgroups,
  useUserAndCustomerGroups,
} from 'utils/hooks/data';
import {
  notificationError,
  notificationSuccess,
  notificationWarningConfirm,
} from 'utils/notifications';
import SensorGroup from 'utils/types/SensorGroup';

export const GroupHierarchyNodeCard = ({
  node,
  groupId,
  toggleNode,
}: {
  node: RawNodeDatum;
  groupId: string;
  toggleNode: () => void;
}) => {
  const { t } = useTranslation('components');

  const [name, setName] = useState(node?.name);

  const [isHovering, setIsHovering] = useState(false);
  const [isFooterHovered, setIsFooterHovered] = useState(false);

  const { userId } = useCurrentUser();
  const { customerId } = useSelectedCustomer();

  const { groups } = useUserAndCustomerGroups(userId, customerId, {
    enableGet: true,
    signPrimaryImage: false,
  });

  const currentGroupId = node.attributes?.id.toString();
  const isCurrentGroupNode = groupId === currentGroupId;

  const navigate = useNavigate();

  const nanoid = customAlphabet('1234567890abcdef', 5);

  const { sensorGroup, updateSensorGroup, deleteSensorGroup } = useSensorGroup(currentGroupId);
  const { addSensorsFromOtherGroupToGroup } = useSensorGroupSensors(currentGroupId, {
    enableGet: false,
  });
  const { addGatewaysFromOtherGroupToGroup } = useSensorGroupGateways(currentGroupId, {
    enableGet: false,
  });

  const { subgroups, createSubgroup, addGroupAsSubgroup } = useSensorGroupSubgroups(currentGroupId);
  const subgroupIds = (subgroups || []).map(subgroup => subgroup.id);

  const { parentGroups } = useSensorGroupParentGroups(currentGroupId);
  const parentGroupIds = (parentGroups || []).map(parentGroup => parentGroup.id);

  const options = useMemo(
    () =>
      groups.filter(
        group =>
          group.id !== currentGroupId &&
          !group.parent_group &&
          !subgroupIds.includes(group.id) &&
          !parentGroupIds.includes(group.id),
      ),
    [groups, currentGroupId, subgroupIds, parentGroupIds],
  );

  // Define card image
  const groupType = useMemo(() => sensorGroup?.type || GroupType.Unknown, [sensorGroup]);

  const { default: illustrationDefault, hovering: illustrationHovering } =
    getIllustrations(groupType);

  const { file } = useSensorGroupFile(
    node?.attributes?.id?.toString(),
    sensorGroup?.sensor_group_file_id,
    {
      disableErrorNotifications: true,
    },
  );

  const imgUrl = file?.storage_url
    ? file.storage_url
    : isHovering || isCurrentGroupNode
      ? illustrationHovering
      : illustrationDefault;

  // Fallback to Tector logo if an error occurs
  const handleImgError = (e: SyntheticEvent<HTMLImageElement, Event>) =>
    (e.currentTarget.src = getFallbackIllustration());

  const onDeleteGroup = async () => {
    const confirmed = await notificationWarningConfirm();
    if (!confirmed) return;

    await deleteSensorGroup(() => {
      notificationSuccess(t('GroupHierarchy.GroupHierarchyNode.onDeleteGroup.successText'));
    });

    navigate(groupId !== currentGroupId ? `/user/groups/${groupId}/overview` : '/user/groups');
  };

  const onAddSubgroup = async () => {
    await createSubgroup({
      name: `Subgroup ${nanoid()}`,
      type: GroupType.Unknown,
    });
  };

  const onAttachSubgroup = async (selectedGroup: SensorGroup) => {
    try {
      await addSensorsFromOtherGroupToGroup(selectedGroup.id);
      await addGatewaysFromOtherGroupToGroup(selectedGroup.id);

      // Add selected group as subgroup
      await addGroupAsSubgroup(selectedGroup.id);
    } catch (error) {
      console.error(error);
    }
  };

  const onUpdateGroupName = async (newName: string) => {
    if (!newName) {
      notificationError(t('GroupHierarchy.GroupHierarchyNode.onUpdateGroup.invalidName'));
      return;
    }

    if (newName !== node.name) {
      await updateSensorGroup({ name: newName });
      notificationSuccess(t('GroupHierarchy.GroupHierarchyNode.onUpdateGroup.successText'));
    }
  };

  if (!currentGroupId) return null;

  return (
    <GroupHierarchyNodeCardBody
      node={node}
      toggleNode={toggleNode}
      isFooterHovered={isFooterHovered}
      isCurrentGroupNode={isCurrentGroupNode}
      imgUrl={imgUrl}
      setIsHovering={setIsHovering}
      handleImgError={handleImgError}
      name={name}
      setName={setName}
      onUpdateGroupName={onUpdateGroupName}
      sensorGroup={sensorGroup}
      groupType={groupType}
      setIsFooterHovered={setIsFooterHovered}
      onAddSubgroup={onAddSubgroup}
      options={options}
      onAttachSubgroup={onAttachSubgroup}
      currentGroupId={currentGroupId}
      onDeleteGroup={onDeleteGroup}
    />
  );
};
