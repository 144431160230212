import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { GroupHierarchy } from 'components';
import { faSquirtyOrganization } from 'components/icons';
import GroupHierarchyTree from 'utils/types/GroupHierarchyTree';

export const GroupHierarchyPreview = ({
  groupId,
  parentName,
  groupHierarchyTree,
  isPending,
  setShowGroupHierarchyModal,
}: {
  groupId: string;
  parentName?: string;
  groupHierarchyTree?: GroupHierarchyTree;
  isPending: boolean;
  setShowGroupHierarchyModal: (value: boolean) => void;
}) => {
  const { t } = useTranslation('components');

  return isPending ? (
    <Skeleton />
  ) : (
    <div
      className="relative h-40 rounded-md mb-3 shadow-sm inset-0 bg-brand-gray-light-4 opacity-65 cursor-pointer select-none"
      onClick={() => setShowGroupHierarchyModal(true)}
    >
      {parentName && (
        <div className="absolute text-xs text-brand-green-light-2 rounded-t-sm bg-brand-gray-light-4 w-full text-center pt-1 pb-1">
          <span>{`${t('GroupHierarchyPreview.parentGroup.text')} ${parentName}`}</span>
        </div>
      )}
      <div className="absolute rounded-md inset-0 flex items-center justify-center bg-brand-gray-light-1 bg-opacity-75 text-white opacity-0 hover:opacity-100 transition-opacity duration-300 gap-1">
        <span>{t('GroupHierarchyPreview.textOnHover')}</span>
        <FontAwesomeIcon icon={faSquirtyOrganization} />
      </div>
      {groupHierarchyTree && (
        <GroupHierarchy
          groupId={groupId}
          groupHierarchyTree={groupHierarchyTree}
          isPreview={true}
        />
      )}
    </div>
  );
};
