import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AdminOnlyDiv from 'components/AdminOnlyDiv';
import InfoText from 'components/InfoText';
import GroupSelectInput from 'components/inputs/GroupSelectInput';
import SensorsAndGatewaysSelectInputModal from 'components/modals/SensorsAndGatewaysSelectInputModal';
import {
  useSensorGroupGateways,
  useSensorGroupParentGroup,
  useSensorGroupSensors,
} from 'utils/hooks/data';
import { notificationSuccess } from 'utils/notifications';
import Gateway from 'utils/types/Gateway';
import Sensor from 'utils/types/Sensor';
import SensorGroup from 'utils/types/SensorGroup';

const SensorGroupDevicesSelectModal: React.FC<{
  sensorGroupId: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  availableSensors?: Sensor[];
  availableGateways?: Gateway[];
  availableGroups?: SensorGroup[];
}> = ({ show, setShow, sensorGroupId, availableSensors, availableGateways, availableGroups }) => {
  const [isUpdatingDevices, setIsUpdatingDevices] = useState(false);

  const { t } = useTranslation('components');

  const {
    sensors: sensorGroupSensors,
    addSensorsToGroup,
    removeSensorsFromGroup,
    addSensorsFromOtherGroupToGroup,
  } = useSensorGroupSensors(sensorGroupId);
  const {
    gateways: sensorGroupGateways,
    addGatewaysToGroup,
    removeGatewaysFromGroup,
    addGatewaysFromOtherGroupToGroup,
  } = useSensorGroupGateways(sensorGroupId);
  const { parentGroup } = useSensorGroupParentGroup(sensorGroupId);
  const hasParent = !!parentGroup;
  const { sensors: parentSensors } = useSensorGroupSensors(parentGroup?.id, {
    enableGet: hasParent,
    disableErrorNotifications: true,
  });
  const { gateways: parentGateways } = useSensorGroupGateways(parentGroup?.id, {
    enableGet: hasParent,
    disableErrorNotifications: true,
  });

  // If group has a parent we can only select sensors/gateways that already exists in the parent
  const parentSensorIds = parentSensors?.map(sensor => sensor.id) || [];
  const parentGatewayIds = parentGateways?.map(gateway => gateway.id) || [];
  availableSensors = hasParent
    ? availableSensors?.filter(sensor => parentSensorIds.includes(sensor.id))
    : availableSensors;
  availableGateways = hasParent
    ? availableGateways?.filter(gateway => parentGatewayIds.includes(gateway.id))
    : availableGateways;

  const updateSensorsSelect = useCallback(
    async (sensorIds: string[]) => {
      if (!availableSensors || !sensorGroupSensors || !addSensorsToGroup || !removeSensorsFromGroup)
        return;

      setIsUpdatingDevices(true);

      // Determine what sensors are allowed to be added/removed
      const allowedSensorIds = availableSensors.map(sensor => sensor.id);
      const sensorGroupSensorIds = sensorGroupSensors.map(sensor => sensor.id);

      // Determine deleted and newly added IDs
      const sensorIdsToAdd = sensorIds
        .filter(sensorId => !sensorGroupSensorIds.includes(sensorId)) // Sensor not already in group
        .filter(sensorId => allowedSensorIds.includes(sensorId)); // Sensor allowed to be added
      const sensorIdsToRemove = sensorGroupSensorIds
        .filter(sensorId => !sensorIds.includes(sensorId)) // Sensor from group was not selected
        .filter(sensorId => allowedSensorIds.includes(sensorId)); // Sensor allowed to be removed

      if (sensorIdsToAdd.length > 0) {
        await addSensorsToGroup(sensorIdsToAdd);
      }
      if (sensorIdsToRemove.length > 0) {
        await removeSensorsFromGroup(sensorIdsToRemove);
      }

      setIsUpdatingDevices(false);
    },
    [availableSensors, sensorGroupSensors, addSensorsToGroup, removeSensorsFromGroup],
  );

  const updateGatewaysSelect = useCallback(
    async (gatewayIds: string[]) => {
      if (
        !availableGateways ||
        !sensorGroupGateways ||
        !addGatewaysToGroup ||
        !removeGatewaysFromGroup
      )
        return;

      // Determine what sensors are allowed to be added/removed
      const allowedGatewayIds = availableGateways.map(sensor => sensor.id);
      const sensorGroupGatewayIds = sensorGroupGateways.map(gateway => gateway.id);

      // Determine deleted and newly added IDs
      const gatewayIdsToAdd = gatewayIds
        .filter(gatewayId => !sensorGroupGatewayIds.includes(gatewayId)) // Gateway not already in group
        .filter(gatewayId => allowedGatewayIds.includes(gatewayId)); // Gateway allowed to be added
      const gatewayIdsToRemove = sensorGroupGatewayIds
        .filter(gatewayId => !gatewayIds.includes(gatewayId)) // Gateway from group was not selected
        .filter(gatewayId => allowedGatewayIds.includes(gatewayId)); // Gateway allowed to be removed

      // NOTE: Must be run in serial to avoid race-conditions with cache
      if (gatewayIdsToAdd.length > 0) {
        await addGatewaysToGroup(gatewayIdsToAdd);
      }
      if (gatewayIdsToRemove.length > 0) {
        await removeGatewaysFromGroup(gatewayIdsToRemove);
      }
    },
    [availableGateways, sensorGroupGateways, addGatewaysToGroup, removeGatewaysFromGroup],
  );

  const onCopyFromGroupSelect = async (group: SensorGroup) => {
    setIsUpdatingDevices(true);
    try {
      await addSensorsFromOtherGroupToGroup(group.id);
      await addGatewaysFromOtherGroupToGroup(group.id);
    } catch (error) {
      console.error(error);
    }
    notificationSuccess(t('modals.SensorsSelectInputModal.copyDevicesSuccess'));
    setIsUpdatingDevices(false);
    setShow(false);
  };

  const groupsToCopyFrom = (availableGroups || []).filter(group => group.id !== sensorGroupId);

  return (
    <SensorsAndGatewaysSelectInputModal
      show={show}
      setShow={setShow}
      isPending={isUpdatingDevices}
      sensors={availableSensors}
      selectedSensors={sensorGroupSensors}
      gateways={availableGateways}
      selectedGateways={sensorGroupGateways}
      onSubmit={async ({ sensorIdsSelected, gatewayIdsSelected }) => {
        await Promise.all([
          updateSensorsSelect(sensorIdsSelected),
          updateGatewaysSelect(gatewayIdsSelected),
        ]);
        setShow(false);
      }}
      appendElements={
        groupsToCopyFrom.length > 0 && (
          <AdminOnlyDiv useCard={false}>
            <div className="mb-5">
              <h2 className="mb-2">
                <FontAwesomeIcon className="mr-2" icon={faFileImport} />
                {t('modals.SensorsSelectInputModal.copyDevices')}
              </h2>
              <GroupSelectInput
                className="mb-1"
                groupsToSelect={groupsToCopyFrom}
                onSelect={onCopyFromGroupSelect}
                isPending={isUpdatingDevices}
              />

              <InfoText text={t('modals.SensorsSelectInputModal.copyDevicesInfo')} />
            </div>
          </AdminOnlyDiv>
        )
      }
      prependElements={
        hasParent ? (
          <InfoText
            className="mb-4"
            text={t('modals.SensorGroupDevicesSelectModal.hasParentsInfoText')}
          />
        ) : undefined
      }
    />
  );
};

export default SensorGroupDevicesSelectModal;
