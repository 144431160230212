import { differenceInHours, subHours } from 'date-fns';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintViewStateContext } from 'utils/contexts';
import { useBlueprintSensorRiskScoreValues } from 'utils/hooks/data';
import { convertRiskScoreToRiskIndex } from 'utils/risk-index';
import { getSensorTransmission } from 'utils/sensor/transmissions';
import { getRiskScoreReasonKeyText } from 'utils/texts';
import { BlueprintViewStateType } from 'utils/types';

export const RiskScoreExplanation: React.FC<{
  blueprintId: string;
  selectedSensorId?: string;
  timeFrom: Date;
  timeTo: Date;
}> = ({ blueprintId, timeFrom, timeTo, selectedSensorId }) => {
  const { t } = useTranslation('components');

  // Get risk score values and latest transmission for showing the current risk score below the plot
  // TODO: Replace this when we have implemented risk scores in the sensor transmissions endpoint
  const { blueprintViewState } = useContext(BlueprintViewStateContext);
  const { sensorId2Transmission: sensorId2TransmissionWithRiskScore } =
    useBlueprintSensorRiskScoreValues(
      blueprintId,
      {
        timeFrom: subHours(timeFrom, 12), // ensuring there is transmisisons from the timelines beginning
        timeTo,
      },
      { enableGet: blueprintViewState === BlueprintViewStateType.RiskScore },
    );
  const max = differenceInHours(timeTo, timeFrom);
  const transmission = selectedSensorId
    ? getSensorTransmission(timeTo, max, max, sensorId2TransmissionWithRiskScore, selectedSensorId)
    : undefined;
  const latestRiskIndex = convertRiskScoreToRiskIndex(transmission?.risk_score);
  const latestRiskReasonIntKeys = transmission?.risk_reason_int_key;

  if (!latestRiskIndex) return null;

  return (
    <div className="text-xs font-light">
      <p className="text-black text-xs">
        {t('blueprints.CanvasMoisturePlot.riskScore')}:{' '}
        <span className="font-bold mr-1">
          {latestRiskIndex.toFixed(1)} {t('blueprints.CanvasMoisturePlot.outOf')} 4
        </span>
        {latestRiskReasonIntKeys &&
          latestRiskReasonIntKeys.length > 0 &&
          t('blueprints.CanvasMoisturePlot.riskScoreReason')}
      </p>
      {latestRiskReasonIntKeys && latestRiskReasonIntKeys.length > 0 && (
        <p className="text-black text-xs">
          <ul className="list-disc pl-4">
            {latestRiskReasonIntKeys.map(key => (
              <li key={key} className="text-xs">
                {getRiskScoreReasonKeyText(key)}
              </li>
            ))}
          </ul>
        </p>
      )}
    </div>
  );
};
