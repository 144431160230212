import { RiskScoreReasonKey } from 'utils/enums';
import TransmissionAnomaly from 'utils/types/TransmissionAnomaly';

type Transmission = {
  id: string;
  timestamp: Date;
  hardware_id: string;
  gateway_id: string;
  event: string;
  battery?: number;
  humidity?: number;
  moisture?: number;
  ohms?: number;
  temperature?: number;
  co2?: number;
  light?: number;
  motion?: number;
  sound_avg?: number;
  sound_peak?: number;
  occupancy?: number;
  voc?: number;
  snr?: number;
  frequency?: number;
  bandwidth?: number;
  spreading_factor?: number;
  rssi?: number;
  downlink_rssi?: number;
  heartbeat_interval?: number;
  tx_power?: number;
  uptime?: number;
  consumed_airtime?: number;

  // Custom properties not saved in database
  isInvalid: boolean;
  anomaly?: TransmissionAnomaly;
  risk_score?: number;
  risk_reason_int_key?: RiskScoreReasonKey[];
};

export const parseTransmission = (transmission: any): Transmission => {
  transmission.timestamp = new Date(transmission.timestamp);
  return transmission as Transmission;
};

export default Transmission;
