import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table } from 'components';
import ExpandableDiv from 'components/ExpandableDiv';
import GatewayLink from 'components/GatewayLink';
import SensorLink from 'components/SensorLink';
import api from 'utils/api';
import { hardwareId2NameFunc, hardwareId2SensorIdFunc, gatewayId2NameFunc } from 'utils/formatting';
import { useTimePeriod } from 'utils/hooks';
import { formatFunctionDate } from 'utils/tables/format-functions';
import Gateway from 'utils/types/Gateway';
import Sensor from 'utils/types/Sensor';
import Transmission from 'utils/types/Transmission';

export const TransmissionsTable: React.FC<{
  tableIdentifier: string;
  data: Transmission[];
  sensors?: Sensor[];
  gateways?: Gateway[];
  loading?: boolean;
  onClick?: (transmission: Transmission) => void;
  exportUrl?: string;
  exportWeatherUrl?: string;
  visibleColumns?: (keyof Transmission)[];
  fromSensorGroupId?: string;
  expandable?: boolean;
  elementAfterTable?: JSX.Element;
}> = ({
  tableIdentifier,
  data,
  sensors = [],
  gateways = [],
  loading,
  visibleColumns,
  onClick,
  exportUrl,
  exportWeatherUrl,
  fromSensorGroupId,
  expandable = false,
  elementAfterTable,
  ...props
}) => {
  const { t } = useTranslation('components');

  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();

  const onExportClick = async (
    event: React.MouseEvent<HTMLButtonElement>,
    url: string,
    filename: string,
  ) => {
    event.preventDefault();

    const blob: Blob = await api.get(url, {
      responseType: 'blob',
    });

    const objectUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = objectUrl;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  };

  const columns = useMemo(() => {
    const hardwareId2Name = hardwareId2NameFunc(sensors);
    const hardwareId2id = hardwareId2SensorIdFunc(sensors);
    const gatewayId2Name = gatewayId2NameFunc(gateways);

    const columnHelper = createColumnHelper<Transmission>();

    return [
      columnHelper.accessor('timestamp', {
        id: 'timestamp',
        header: () => t('tables.TransmissionsTable.columns.timestamp.text'),
        sortingFn: 'datetime',
        sortDescFirst: true,
        enableColumnFilter: false,
        enableHiding: false,
        size: 215,
        cell: ({ getValue }) => formatFunctionDate(getValue()),
      }),

      columnHelper.accessor('hardware_id', {
        id: 'hardware_id',
        header: () => t('tables.TransmissionsTable.columns.hardware_id.text'),
        enableColumnFilter: false,
        enableHiding: sensors.length !== 0,
        size: 225,
        cell: ({ getValue }) => {
          const value = getValue();
          const name = hardwareId2Name[value];
          const sensorId = hardwareId2id[value];
          return (
            <SensorLink
              sensorId={sensorId}
              fromSensorGroupId={fromSensorGroupId}
              timeFrom={timeFrom}
              timeTo={timeTo}
              datatip={t('tables.TransmissionsTable.columns.hardware_id.dataTip.1')}
              externalDatatip={t('tables.TransmissionsTable.columns.hardware_id.dataTip.2')}
            >
              {name}
            </SensorLink>
          );
        },
        meta: {
          hidden: !visibleColumns?.includes('hardware_id'),
        },
      }),

      columnHelper.accessor('temperature', {
        id: 'temperature',
        header: () => t('tables.TransmissionsTable.columns.temperature.text'),
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('temperature'),
        },
        size: 200,
        cell: ({ getValue }) => (getValue() ? getValue()?.toFixed(1) : ''),
      }),

      columnHelper.accessor('humidity', {
        id: 'humidity',
        header: () => t('tables.TransmissionsTable.columns.humidity.text'),
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('humidity'),
        },
        enableSorting: false,
        enableColumnFilter: false,
        size: 200,
      }),

      columnHelper.accessor('moisture', {
        id: 'moisture',
        header: () => t('tables.TransmissionsTable.columns.moisture.text'),
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('moisture'),
        },
        enableSorting: false,
        enableColumnFilter: false,
        size: 150,
        cell: ({
          getValue,
          cell: {
            row: { original },
          },
        }) =>
          original.isInvalid ? (
            <span
              data-tooltip-content={t('tables.TransmissionsTable.columns.moisture.dataTip')}
              data-tooltip-id="route-tooltip"
            >
              n/a
            </span>
          ) : getValue() ? (
            getValue()?.toFixed(1)
          ) : (
            ''
          ),
      }),

      columnHelper.accessor('ohms', {
        id: 'ohms',
        header: () => t('tables.TransmissionsTable.columns.ohms.text'),
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('ohms'),
        },
        size: 200,
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ getValue }) => (getValue() ? getValue()?.toFixed(3) : ''),
      }),

      columnHelper.accessor('battery', {
        id: 'battery',
        header: () => t('tables.TransmissionsTable.columns.battery.text'),
        size: 100,
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('battery'),
        },
        enableSorting: false,
        enableColumnFilter: false,
        enableHiding: false,
      }),

      columnHelper.accessor('snr', {
        id: 'snr',
        header: () => t('tables.TransmissionsTable.columns.snr.text'),
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('snr'),
        },
        size: 100,
        enableSorting: false,
        enableColumnFilter: false,
      }),

      columnHelper.accessor('frequency', {
        id: 'frequency',
        header: () => t('tables.TransmissionsTable.columns.frequency.text'),
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('frequency'),
        },
        enableSorting: false,
        enableColumnFilter: false,
        enableHiding: false,
        size: 125,
      }),

      columnHelper.accessor('rssi', {
        id: 'rssi',
        header: () => t('tables.TransmissionsTable.columns.rssi.text'),
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('rssi'),
        },
        enableSorting: false,
        enableColumnFilter: false,
        size: 150,
      }),

      columnHelper.accessor('downlink_rssi', {
        id: 'downlink_rssi',
        header: () => t('tables.TransmissionsTable.columns.downlink_rssi.text'),
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('downlink_rssi'),
        },
        size: 230,
      }),

      columnHelper.accessor('heartbeat_interval', {
        id: 'heartbeat_interval',
        header: () => t('tables.TransmissionsTable.columns.heartbeat_interval.text'),
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('heartbeat_interval'),
        },
        size: 175,
        enableSorting: false,
        enableColumnFilter: false,
      }),

      columnHelper.accessor('tx_power', {
        id: 'tx_power',
        header: () => t('tables.TransmissionsTable.columns.tx_power.text'),
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('tx_power'),
        },
        enableSorting: false,
        enableColumnFilter: false,
        size: 200,
      }),

      columnHelper.accessor('uptime', {
        id: 'uptime',
        header: () => t('tables.TransmissionsTable.columns.uptime.text'),
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('uptime'),
        },
        enableSorting: false,
        enableColumnFilter: false,
        enableHiding: false,
        size: 150,
      }),

      columnHelper.accessor('spreading_factor', {
        id: 'spreading_factor',
        header: () => t('tables.TransmissionsTable.columns.spreading_factor.text'),
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('spreading_factor'),
        },
        enableSorting: false,
        enableColumnFilter: false,
        size: 200,
      }),

      columnHelper.accessor('bandwidth', {
        id: 'bandwidth',
        header: () => t('tables.TransmissionsTable.columns.bandwidth.text'),
        meta: {
          className: 'text-right',
          hidden: !visibleColumns?.includes('bandwidth'),
        },
        enableSorting: false,
        enableColumnFilter: false,
        enableHiding: false,
        size: 150,
      }),

      columnHelper.accessor('gateway_id', {
        id: 'gateway_id',
        header: () => t('tables.TransmissionsTable.columns.gateway_id.text'),
        size: 250,
        enableColumnFilter: false,
        enableHiding: gateways.length !== 0,
        cell: ({ getValue }) => {
          const value = getValue();
          const name = gatewayId2Name[value];

          return (
            <GatewayLink
              gatewayId={value}
              fromSensorGroupId={fromSensorGroupId}
              timeFrom={timeFrom}
              timeTo={timeTo}
              datatip={t('tables.TransmissionsTable.columns.gateway_id.dataTip.1')}
              externalDatatip={t('tables.TransmissionsTable.columns.gateway_id.dataTip.2')}
            >
              {name ? name : value}
            </GatewayLink>
          );
        },
        meta: {
          hidden: !visibleColumns?.includes('gateway_id'),
        },
      }),
    ];
  }, [sensors, gateways, visibleColumns, t, fromSensorGroupId, timeFrom, timeTo]);

  const elements = (
    <>
      <Table
        tableIdentifier={`transmissions-table-${tableIdentifier}`}
        data={data}
        loading={loading}
        columns={columns}
        pageSize={5}
        onClick={onClick}
        sortBy={[
          {
            id: 'timestamp',
            desc: true,
          },
        ]}
        {...props}
      />
      {elementAfterTable}
    </>
  );

  return expandable ? (
    <ExpandableDiv
      title={t('tables.transmissionsTable.rawValues.title')}
      elementNextToTitle={
        exportUrl || exportWeatherUrl ? (
          <div className="flex justify-end lg:justify-between gap-3">
            {exportWeatherUrl && (
              <button
                className="text-xs lg:text-base text-brand-gray-light-3 hover:text-blue-500 underline"
                onClick={e => onExportClick(e, exportWeatherUrl, 'weather.csv')}
              >
                <>{t('tables.TransmissionsTable.button.textWeather')}</>
              </button>
            )}

            {exportUrl && (
              <>
                <button
                  className="text-xs lg:text-base text-brand-orange hover:text-blue-500 underline"
                  onClick={e => onExportClick(e, exportUrl, 'transmissions.csv')}
                >
                  <>{t('tables.TransmissionsTable.button.text')} CSV</>
                </button>

                <button
                  className="text-xs lg:text-base text-brand-orange hover:text-blue-500 underline"
                  onClick={e =>
                    onExportClick(e, `${exportUrl}&export_as_excel=true`, 'transmissions.xlsx')
                  }
                >
                  <>{t('tables.TransmissionsTable.button.text')} Excel</>
                </button>
              </>
            )}
          </div>
        ) : undefined
      }
      tooltipExpand={t('tables.TransmissionsTable.ExpandButton.hide')}
      tooltipCollapse={t('tables.TransmissionsTable.ExpandButton.show')}
      {...props}
    >
      {elements}
    </ExpandableDiv>
  ) : (
    elements
  );
};
