import { AlertSeverityLevel } from 'utils/enums';
import AlarmType from 'utils/enums/AlarmType';
import User from 'utils/types/User';

type Alarm = {
  id: string;

  alarm_id: string;
  type: AlarmType;
  transmission_id?: string;
  transmission_before_id?: string;
  sensor_id?: string;
  hardware_id?: string;
  gateway_id?: string;
  alarm_rule_id?: string;
  severity_level?: AlertSeverityLevel;

  // Resolved details
  resolved_reason?: string;
  resolved_time?: Date;
  resolved_by?: User;

  // Responsible
  responsible_user?: User;

  created_date: Date;
};

export const parseAlarm = (alarm: any): Alarm => {
  alarm.created_date = new Date(alarm.created_date);
  return alarm as Alarm;
};

export default Alarm;
