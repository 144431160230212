import { Formik } from 'formik';
import { MutableRefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  Alert,
  Button,
  SubmittingButton,
  CustomerSelectInput,
  FormTextInput,
  GlobalAddressInput,
} from 'components';
import GroupTypeSelectInput from 'components/inputs/GroupTypeSelectInput';
import SinglePointMap from 'components/maps/SinglePointMap';
import GroupType from 'utils/enums/GroupType';
import Customer from 'utils/types/Customer';
import { SensorGroupUpdate } from 'utils/types/SensorGroup';

export type SensorGroupFormOutputValues = SensorGroupUpdate & {
  customerId?: string;
  userId?: string;
  type?: GroupType;
};

export type SensorGroupFormInputValues = Partial<SensorGroupFormOutputValues>;

const SensorGroupForm: React.FC<{
  onSubmit: (values: SensorGroupFormOutputValues) => Promise<void>;
  values?: SensorGroupFormInputValues;
  onDelete?: () => Promise<void>;
  buttonText?: string;
  submittingText?: string;
  showAlert?: boolean;
  showCustomer?: boolean;
  showAddress?: boolean;
  showType?: boolean;
  showMap?: boolean;
  showDescription?: boolean;
  readOnly?: boolean;
}> = ({
  onSubmit,
  values,
  onDelete,
  buttonText,
  submittingText,
  showAlert = false,
  showCustomer = false,
  showAddress = false,
  showType = false,
  showMap = false,
  showDescription = false,
  readOnly = false,
}) => {
  const refGroupTypeInput = useRef<HTMLInputElement>() as
    | MutableRefObject<HTMLInputElement>
    | undefined;

  const { t } = useTranslation('components');

  const schema = yup.object({
    name: yup.string().required(),
    customerId: yup.string().nullable(),
    userId: yup.string().nullable(),
    description: yup.string().nullable(),
    type: yup.number().integer().default(GroupType.Unknown).nullable(),
    geographic_location: yup
      .object({
        address: yup.string().nullable(),
        latitude: yup
          .number()
          .typeError(t('forms.SensorGroupForm.errors.numberLatitude'))
          .min(-90, t('forms.SensorGroupForm.errors.minLatitude'))
          .max(90, t('forms.SensorGroupForm.errors.maxLatitude'))
          .nullable(),
        longitude: yup
          .number()
          .typeError(t('forms.SensorGroupForm.errors.numberLongitude'))
          .min(-180, t('forms.SensorGroupForm.errors.minLongitude'))
          .max(180, t('forms.SensorGroupForm.errors.maxLongitude'))
          .nullable(),
      })
      .nullable(),
  });

  const initialValues = schema.cast(values, {
    assert: false,
    stripUnknown: true,
  }) as SensorGroupFormInputValues;

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      onSubmit={async values => await onSubmit(values as SensorGroupFormOutputValues)}
      initialValues={initialValues}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit, values: valuesFormik, setFieldValue, isSubmitting }) => {
        const onAddressSelected = ({
          lat,
          lon,
          display_name,
        }: {
          lat: number;
          lon: number;
          display_name: string;
        }) => {
          setFieldValue('geographic_location.address', display_name);
          setFieldValue('geographic_location.longitude', lon);
          setFieldValue('geographic_location.latitude', lat);
        };

        const onCustomerSelect = async (customer: Customer) => {
          setFieldValue('customerId', customer.id);
        };

        return (
          <form noValidate onSubmit={handleSubmit}>
            <FormTextInput
              label={t('forms.SensorGroupForm.name.label')}
              name="name"
              autoFocus
              data-testid="name"
              required
              readOnly={readOnly}
              disabled={readOnly}
            />

            {showDescription && (
              <FormTextInput
                label={t('forms.SensorGroupForm.description.label')}
                name="description"
                data-testid="description"
                readOnly={readOnly}
                disabled={readOnly}
              />
            )}

            {showCustomer && (
              <CustomerSelectInput
                className="mb-3"
                label={t('forms.SensorGroupForm.customer.label')}
                onSelect={onCustomerSelect}
                initialSelectedCustomerId={valuesFormik.customerId}
              />
            )}

            {showAddress && (
              <div className="mb-3.5">
                <label htmlFor="address-input">{t('forms.SensorGroupForm.address.label')}</label>
                <GlobalAddressInput
                  onAddressSelected={onAddressSelected}
                  initialValue={valuesFormik.geographic_location?.address}
                  disabled={readOnly}
                />
              </div>
            )}

            {showAlert && !readOnly && (
              <Alert variant="info" className="shadow rounded p-4 mb-2">
                <small>
                  <strong>{t('forms.SensorGroupForm.alert.text.1')}</strong>:{' '}
                  {t('forms.SensorGroupForm.alert.text.2')}
                </small>
              </Alert>
            )}

            {showType && (
              <GroupTypeSelectInput
                className="mb-3"
                ref={refGroupTypeInput}
                onSelect={groupType => setFieldValue('type', groupType)}
                initialSelectedOption={values?.type}
                includeLabel
                isDisabled={readOnly}
              />
            )}

            {!readOnly && (
              <div className="mb-2">
                <SubmittingButton
                  submittingText={submittingText ?? t('forms.SensorGroupForm.submittingText')}
                  submitting={isSubmitting}
                  buttonText={buttonText ?? t('forms.SensorGroupForm.buttonText')}
                />

                {onDelete && (
                  <Button
                    className="ml-3.5"
                    variant="outline-danger"
                    disabled={isSubmitting}
                    onClick={() => onDelete()}
                  >
                    {t('forms.SensorGroupForm.deleteButton.text')}
                  </Button>
                )}
              </div>
            )}

            {showMap &&
              valuesFormik.geographic_location?.latitude &&
              valuesFormik.geographic_location?.longitude && (
                <SinglePointMap
                  latitude={valuesFormik.geographic_location?.latitude}
                  longitude={valuesFormik.geographic_location?.longitude}
                />
              )}
          </form>
        );
      }}
    </Formik>
  );
};

export default SensorGroupForm;
