import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import AlarmRule, {
  parseAlarmRule,
  getQueryKeyAdminAlarmRuleCustomers,
} from 'utils/types/AlarmRule';
import { getQueryKeyCustomerAlarmRules, parseCustomer } from 'utils/types/Customer';
import HookOptions from 'utils/types/HookOptions';

export const useAdminAlarmRuleCustomers = (
  alarmRuleId?: string,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = getQueryKeyAdminAlarmRuleCustomers(alarmRuleId!);

  const { isAdmin } = useCurrentUser();

  const queryClient = useQueryClient();

  // Get
  const { data: customers, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/admin/alarm_rules/${alarmRuleId}/customers`),
    select: (customers: any[]) => customers.map(parseCustomer),
    enabled: enableGet && isAdmin && !!alarmRuleId,
  });

  // Attach customer by id
  const { mutateAsync: attachCustomerById } = useMutation({
    mutationFn: async (customerId: string): Promise<AlarmRule[]> => {
      const alarmRules = await api.post(
        `/admin/customers/${customerId}/alarm_rules/${alarmRuleId}`,
      );
      return alarmRules.map(parseAlarmRule);
    },
    onSuccess: async (alarmRules, customerId) => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: getQueryKeyCustomerAlarmRules(customerId) });
    },
  });

  // Detach customer by id
  const { mutateAsync: detatchCustomerById } = useMutation({
    mutationFn: async (customerId: string): Promise<AlarmRule[]> => {
      const alarmRules = await api.delete(
        `/admin/customers/${customerId}/alarm_rules/${alarmRuleId}`,
      );
      return alarmRules.map(parseAlarmRule);
    },
    onSuccess: async (alarmRules, customerId) => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: getQueryKeyCustomerAlarmRules(customerId) });
    },
  });

  return {
    attachCustomerById,
    detatchCustomerById,
    customers,
    isPending,
  };
};
