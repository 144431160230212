import {
  faBell,
  faBellSlash,
  faCog,
  faCubes,
  faDiagramProject,
  faDraftingCompass,
  faFile,
  faFileAlt,
  faImages,
  faLocationArrow,
  faPlayCircle,
  faSatelliteDish,
  faSitemap,
  faSkullCrossbones,
  faTable,
  faTruck,
  faUser,
  faUsers,
  faUserShield,
  faUserTie,
  faCircleNodes,
  faExclamationTriangle,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons';

import faSquirtyBlueprint from 'components/icons/faSquirtyBlueprint1';
import faSquirtyBricks1 from 'components/icons/faSquirtyBricks1';
import faSquirtyBricks2 from 'components/icons/faSquirtyBricks2';
import faSquirtyBuildings from 'components/icons/faSquirtyBuildings';
import faSquirtyCasing from 'components/icons/faSquirtyCasing';
import faSquirtyChartDown from 'components/icons/faSquirtyChartDown';
import faSquirtyChartForecast from 'components/icons/faSquirtyChartForecast';
import faSquirtyChartUp from 'components/icons/faSquirtyChartUp';
import faSquirtyCompass from 'components/icons/faSquirtyCompass';
import faSquirtyConstructionCrane from 'components/icons/faSquirtyConstructionCrane';
import faSquirtyCrane from 'components/icons/faSquirtyCrane';
import faSquirtyHouse1 from 'components/icons/faSquirtyHouse1';
import faSquirtyInsulation from 'components/icons/faSquirtyInsulation';
import faSquirtyKitchen from 'components/icons/faSquirtyKitchen';
import faSquirtyNoAlert from 'components/icons/faSquirtyNoAlert';
import faSquirtyRoomType from 'components/icons/faSquirtyRoomType';
import faSquirtyScrew from 'components/icons/faSquirtyScrew';
import faSquirtySensor from 'components/icons/faSquirtySensor';
import faSquirtySwitch from 'components/icons/faSquirtySwitch';
import faSquirtyWall from 'components/icons/faSquirtyWall';
import { getSensorTagIcon } from 'components/icons/SensorTags';

// Tector icons
const faSquirtyAdmin = faUserShield;
const faSquirtyAlarm = faExclamationTriangle;
const faSquirtyAnomaly = faSkullCrossbones;
const faSquirtyCustomer = faUserTie;
const faSquirtyDemoApplication = faPlayCircle;
const faSquirtyDocumentChange = faFile;
const faSquirtyGateway = faSatelliteDish;
const faSquirtyGroup = faCubes;
const faSquirtyGroupTypeDelivery = faTruck;
const faSquirtyGroupTypeProject = faDiagramProject;
const faSquirtyGroupTypeUnknown = faSquirtyGroup;
const faSquirtyImages = faImages;
const faSquirtyNoAlarm = faSquirtyNoAlert;
const faSquirtyOrganization = faSitemap;
const faSquirtyOverview = faGlobe;
const faSquirtyPosition = faDraftingCompass;
const faSquirtyReport = faFileAlt;
const faSquirtySettings = faCog;
const faSquirtyTags = faLocationArrow;
const faSquirtyTransmissions = faTable;
const faSquirtyUser = faUser;
const faSquirtyUsers = faUsers;
const faSquirtyCircleNodes = faCircleNodes;
const faSquirtyNotification = faBell;
const faSquirtyNotificationOff = faBellSlash;

export {
  faSquirtyAdmin,
  faSquirtyAlarm,
  faSquirtyAnomaly,
  faSquirtyBlueprint,
  faSquirtyBricks1,
  faSquirtyBricks2,
  faSquirtyBuildings,
  faSquirtyCasing,
  faSquirtyChartDown,
  faSquirtyChartForecast,
  faSquirtyChartUp,
  faSquirtyCircleNodes,
  faSquirtyCompass,
  faSquirtyConstructionCrane,
  faSquirtyCrane,
  faSquirtyCustomer,
  faSquirtyDemoApplication,
  faSquirtyDocumentChange,
  faSquirtyGateway,
  faSquirtyGroup,
  faSquirtyGroupTypeDelivery,
  faSquirtyGroupTypeProject,
  faSquirtyGroupTypeUnknown,
  faSquirtyHouse1,
  faSquirtyImages,
  faSquirtyInsulation,
  faSquirtyKitchen,
  faSquirtyNoAlarm,
  faSquirtyOrganization,
  faSquirtyOverview,
  faSquirtyPosition,
  faSquirtyReport,
  faSquirtyRoomType,
  faSquirtyScrew,
  faSquirtySensor,
  faSquirtySettings,
  faSquirtySwitch,
  faSquirtyTags,
  faSquirtyTransmissions,
  faSquirtyUser,
  faSquirtyUsers,
  faSquirtyWall,
  faSquirtyNotification,
  faSquirtyNotificationOff,
  getSensorTagIcon,
};
