import classNames from 'classnames';

import { GroupTypeBadge } from 'components';
import Card from 'components/cards/Card';
import {
  Name,
  Description,
  DetachButton,
  AddButton,
  AttachButton,
  DeleteButton,
  ExpandButton,
  DevicesButton,
} from 'components/GroupHierarchy/components/GroupHierarchyNode/components/GroupHierarchyNodeCard/components';
import { Props } from 'components/GroupHierarchy/components/GroupHierarchyNode/components/GroupHierarchyNodeCard/components/GroupHierarchyNodeCardBody/GroupHierarchyNodeCardBody';

export const GroupHierarchyNodeCardBodyChrome = ({
  node,
  toggleNode,
  isFooterHovered,
  isCurrentGroupNode,
  imgUrl,
  setIsHovering,
  handleImgError,
  name,
  setName,
  onUpdateGroupName,
  sensorGroup,
  groupType,
  setIsFooterHovered,
  onAddSubgroup,
  options,
  onAttachSubgroup,
  currentGroupId,
  onDeleteGroup,
  navigateToGroupText,
}: Props & {
  navigateToGroupText: string;
}) => (
  <div className="relative card-hover-effect shadow-sm group">
    <span
      className={classNames(
        'absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-brand-gray-light-4 rounded-md p-2 text-xs text-brand-green-light-3 opacity-0 transition-opacity duration-300 z-10',
        {
          'opacity-0': isFooterHovered,
          'group-hover:opacity-95': !isFooterHovered,
        },
      )}
    >
      {navigateToGroupText}
    </span>
    <Card
      className={classNames('mb-5', {
        'border-4 border-brand-lime-light-1': isCurrentGroupNode,
      })}
      imgClassName="rounded-t-xl"
      imgUrl={imgUrl}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      ImgOnError={handleImgError}
      imgHeight={140}
      imgObjectFit="cover"
      body={
        <>
          <Name name={name} setName={setName} onUpdateGroupName={onUpdateGroupName} />
          <Description description={sensorGroup?.description} />

          <div className="absolute top-2 left-2 opacity-70">
            <GroupTypeBadge className="text-xs" type={groupType} size="xs" />
          </div>
        </>
      }
      footer={
        <div
          className="flex justify-between mt-4"
          onMouseEnter={() => {
            setIsFooterHovered(true);
          }}
          onMouseLeave={() => {
            setIsFooterHovered(false);
          }}
        >
          <div className="flex gap-2 items-center">
            <>
              <AddButton
                disabled={node.attributes?.tree_depth === 3}
                onAddSubgroup={onAddSubgroup}
              />
              <AttachButton
                disabled={node.attributes?.tree_depth === 3}
                options={options}
                onAttachSubgroup={onAttachSubgroup}
              />
            </>

            {node.attributes?.parent_group && (
              <>
                <DetachButton
                  groupId={currentGroupId}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                />

                <DeleteButton onDeleteGroup={onDeleteGroup} />
              </>
            )}

            <DevicesButton sensorGroupId={currentGroupId} />
          </div>

          {node.children && <ExpandButton toggleNode={toggleNode} />}
        </div>
      }
    />
  </div>
);
