import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useQueryUpdate } from 'utils/queries/update';
import AlarmNotification, { parseAlarmNotification } from 'utils/types/AlarmNotification';

export const useAlarmNotification = (alarmNotificationId?: string) => {
  const queryKey = ['alarmNotifications', alarmNotificationId];

  const { data: alarmNotification, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/alarm_notifications/${alarmNotificationId}`),
    select: (alarmNotification: any) => parseAlarmNotification(alarmNotification),
    enabled: !!alarmNotificationId,
  });

  // Update
  const { mutateAsync: updateAlarmNotification } = useQueryUpdate<AlarmNotification>({
    api,
    queryKey: ['alarmNotifications', alarmNotificationId],
    endpoint: `/alarm_notifications/${alarmNotificationId}`,
    invalidateQueryKeys: [
      ['alarmNotifications'],
      ['userAlarmNotifications'],
      ['userAlarmNotificationsUnseen'],
      ['userAlarmNotificationsUnseenCount'],
    ],
  });

  return {
    alarmNotification,
    updateAlarmNotification,
    isPending,
  };
};
