import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { RawNodeDatum } from 'react-d3-tree';
import { useTranslation } from 'react-i18next';

import {
  GroupHierarchyNodeCardBodySafari,
  GroupHierarchyNodeCardBodyChrome,
} from 'components/GroupHierarchy/components/GroupHierarchyNode/components/GroupHierarchyNodeCard/components/GroupHierarchyNodeCardBody/components';
import { isSafari } from 'utils/browsers';
import GroupType from 'utils/enums/GroupType';
import SensorGroup from 'utils/types/SensorGroup';

export type Props = {
  node: RawNodeDatum;
  toggleNode: () => void;
  isFooterHovered: boolean;
  isCurrentGroupNode: boolean;
  imgUrl: string;
  setIsHovering: Dispatch<SetStateAction<boolean>>;
  handleImgError: (e: SyntheticEvent<HTMLImageElement, Event>) => string;
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  onUpdateGroupName: (newName: string) => Promise<void>;
  sensorGroup: SensorGroup | undefined;
  groupType: GroupType;
  setIsFooterHovered: Dispatch<SetStateAction<boolean>>;
  onAddSubgroup: () => Promise<void>;
  options: SensorGroup[];
  onAttachSubgroup: (selectedGroup: SensorGroup) => Promise<void>;
  currentGroupId: string;
  onDeleteGroup: () => Promise<void>;
};

export const GroupHierarchyNodeCardBody = ({ ...props }: Props) => {
  const { t } = useTranslation('components');

  const navigateToGroupText = t('GroupHierarchy.GroupHierarchyNode.onUpdateGroup.NavigateToGroup');

  // NOTE: We need to render different components for Safari and Chrome because of the way Safari handles the <foreignObject> element.
  //       More details about this issue can be found here: https://github.com/bkrem/react-d3-tree/issues/284
  return isSafari ? (
    <GroupHierarchyNodeCardBodySafari navigateToGroupText={navigateToGroupText} {...props} />
  ) : (
    <GroupHierarchyNodeCardBodyChrome navigateToGroupText={navigateToGroupText} {...props} />
  );
};
