import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DocumentChangesTable, Button } from 'components';
import AdminOnlyDiv from 'components/AdminOnlyDiv';
import SensorGroupForm from 'components/forms/SensorGroupForm';
import Modal from 'components/modals/Modal';
import { Props } from 'components/modals/UpdateSensorGroupModal';
import { GroupAccessTable } from 'components/tables/GroupAccessTable';
import GroupType from 'utils/enums/GroupType';
import { useCurrentUser } from 'utils/hooks';
import { useSensorGroupChanges } from 'utils/hooks/data';
import SensorGroup from 'utils/types/SensorGroup';

export const ConfigureGroupModal: React.FC<
  PropsWithChildren<
    Omit<Props, 'values' | 'showCustomer'> & {
      group?: SensorGroup;
    }
  >
> = ({ onSubmit, show, setShow, group, onDelete }) => {
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation('components');
  const { isAdmin } = useCurrentUser();
  const { documentChanges } = useSensorGroupChanges(group?.id, {
    enableGet: showModal && isAdmin,
  });

  const documentChangesMemo = useMemo(() => documentChanges || [], [documentChanges]);

  return (
    <Modal
      title={t('modals.ConfigureGroupModal.title')}
      show={show}
      setShow={setShow}
      closeOnClickOutside={false}
    >
      <div className="mb-5">
        <SensorGroupForm
          onSubmit={async values => await onSubmit(values)}
          values={group}
          onDelete={onDelete}
          showDescription
          showAlert
          showAddress
          showType
          readOnly={group?.type === GroupType.Delivery}
        />
      </div>

      <div className="mb-10">
        <h2 className="mb-3">{t('modals.ConfigureGroupModal.accessTitle')}</h2>
        <div className="mb-3">
          <GroupAccessTable groupId={group?.id} showModal={showModal} setShowModal={setShowModal} />
        </div>
        <Button variant="primary" onClick={() => setShowModal(true)}>
          <FontAwesomeIcon className="mr-2" icon={faPlus} />
          {t('modals.ConfigureGroupModal.shareAccess')}
        </Button>
      </div>
      <div className="mb-5">
        <AdminOnlyDiv useCard={false}>
          <h2 className="mt-10 mb-3">
            {t('modals.UpdateSensorGroupModal.AdminOnly.groupChanges')}
          </h2>
          <DocumentChangesTable
            tableIdentifier={group?.id || 'configure-group-modal'}
            data={documentChangesMemo}
            columnsToShow={['field', 'value', 'created_date', 'changed_by']}
          />
        </AdminOnlyDiv>
      </div>
    </Modal>
  );
};
